<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitada"
                    label="Inhabilitada"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="marcas"
        :loading="load"
        :search="search"
        sort-by="nombre"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.inhabilitada`]="{ item }">
          <span
            v-text="item.inhabilitada ? 'SI' : 'NO'"
          ></span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.inhabilitada"
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-pen
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitada ? 'success' : 'error'"
            :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitada
              ? `¿Desea habilitar la marca <strong>${item.nombre}</strong>?`
              : `¿Desea inhabilitar la marca <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Marca
      v-model="dialog"
      :p_nueva="nueva"
      :p_marca="marca"
      @editar="editar"
    />
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import Marca from '../../components/generales/Marca'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      marcas: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Inhabilitada', value: 'inhabilitada', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      marca: {
        codigo: null,
        nombre: null
      },
      filtro: {
        nombre: '',
        inhabilitada: 0
      }
    }
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    Marca
  },
  methods: {
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('marcas/habilitar', item.codigo)
        .then((res) => {
          // actualiza la marca en la lista
          item.inhabilitada = 0
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('marcas/inhabilitar', item.codigo)
        .then((res) => {
          // actualiza la marca en la lista
          item.inhabilitada = 1
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.marcas = []
      await this.$store.dispatch('marcas/get_marcas', this.filtro)
        .then((res) => {
          this.marcas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    editar (marca) {
      // actualiza el nombre de la marca en la lista
      this.marcas.find(mar => mar.codigo == marca.codigo).nombre = marca.nombre.toUpperCase()
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.marca = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.marca = {
          codigo: null,
          nombre: null
        }
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        nombre: '',
        inhabilitada: 0
      }
    }
  }
}
</script>